:root {
  --font1: "Montserrat", sans-serif;
  --font2: "Poppins", sans-serif;
  --font3: "Urbanist", sans-serif;
}
* {
  font-family: var(--font2);
}
.container-lg {
  max-width: 1280px;
}
.dropdown-menu[data-bs-popper] {
  left: unset !important;
  right: 0;
}
.dropdown-toggle::after {
  width: 24px;
  height: 24px;
  background-image: url(./assets/icons/down.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-12px);
}
@media (max-width: 1399.98px) {
  .container-lg {
    padding: 0 80px;
    max-width: 100%;
  }
}
@media (max-width: 1199.98px) {
  .container-lg {
    padding: 0 40px;
  }
}
@media (max-width: 992.98px) {
  .container-lg {
    padding: 0 30px;
    max-width: 100%;
  }
}
@media (max-width: 768.98px) {
  .container-lg {
    padding: 0 24px;
    max-width: 100%;
  }
}
/* Navbar start */
.navbar {
  border-radius: 0 0 30px 30px;
  box-shadow: 0px 4px 24px 0px #00000033;
  padding: 20px 0 !important;
  background: #fff;
  height: 87px;
}
.navbar .navbar-brand {
  color: #000;
  font-family: var(--font1);
  font-size: 30.4px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
.navbar .dropdown button {
  border: none;
  background: none;
  color: #000;
  font-family: var(--font3);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.navbar .dropdown img {
  width: 59px;
  height: 59px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.navbar .add {
  width: 59px;
  height: 59px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #7296a4;
  justify-content: center;
  align-items: center;
  display: none;
}
@media (max-width: 575.98px) {
  .navbar .add {
    display: flex;
    position: absolute;
    top: 20px;
    left: 24px;
  }
  .navbar .container-lg{
    justify-content: end;
  }
  .navbar .navbar-brand{
    position: absolute;
    left: 50%;
    top: 26px;
    transform: translateX(-65px);
  }
  .navbar .dropdown button {
    padding: 0;
  }
  .navbar .dropdown button span {
    display: none;
  }
  .dropdown-toggle::after {
    content: unset;
  }
  .navbar .dropdown img {
    margin-right: unset;
  }
}
@media (max-width: 400.98px) {
  .navbar .navbar-brand {
    font-size: 20.4px;
    transform: translateX(-40px);
  }
  .navbar .dropdown img,
  .navbar .add {
    width: 40px;
    height: 40px;
  }
}
/* Hero Section Starts */
.heroSection {
  background-image: url(./assets/imges/hero-bg.png);
  background-size: cover;
  background-position: center;
  padding: 100px 0 0;
  overflow: hidden;
}
.heroMain h1 {
  color: #000;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.heroMain .content {
  position: relative;
  z-index: 1;
}
.heroMain h1 span {
  color: #7296a4;
  display: block;
  font-size: 72px;
  line-height: 108px;
}
.heroMain p {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  max-width: 518px;
  margin-bottom: 30px;
}
.heroMain a {
  padding: 16px 24px;
  border-radius: 14px;
  background: #7296a4;
  border: none;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.36px;
  text-decoration: none;
}
.heroMain button:hover {
  opacity: 0.8;
  transition: all 0.5s;
}
.heroMain .imgHead {
  position: relative;
}
.heroMain img {
  width: 100%;
  position: relative;
}
.heroMain .imgHead::before {
  content: "";
  position: absolute;
  left: -90px;
  top: -90px;
  width: 400px;
  height: 400px;
  background-image: url(./assets/imges/vector-1.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 1399.98px) {
  .heroMain h1 {
    font-size: 56px;
  }
  .heroMain h1 span {
    font-size: 62px;
  }
}
@media (max-width: 1199.98px) {
  .heroMain h1 {
    font-size: 50px;
  }
  .heroMain h1 span {
    font-size: 54px;
  }
}
@media (max-width: 991.98px) {
  .heroMain h1 {
    font-size: 38px;
  }
  .heroMain h1 span {
    font-size: 42px;
    line-height: 60px;
  }
  .heroMain p {
    font-size: 16px;
  }
  .heroSection {
    padding-top: 80px;
  }
}
@media (max-width: 767.98px) {
  .heroMain p {
    max-width: 100%;
  }
  .heroSection {
    padding-top: 60px;
  }
  .heroMain .content {
    margin-bottom: 40px;
    padding: 0 24px !important;
  }
  .heroMain {
    padding: 0;
  }
}
@media (max-width: 575.98px) {
  .heroMain h1 {
    font-size: 31px;
  }
  .heroMain h1 span {
    font-size: 36px;
    line-height: 40px;
  }
  .heroMain p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .heroSection {
    padding-top: 50px;
  }
}
@media (max-width: 400.98px) {
  .heroSection {
    padding-top: 30px;
  }
  .heroMain h1 {
    font-size: 21px;
  }
  .heroMain h1 span {
    font-size: 23px;
    line-height: 30px;
  }
  .heroMain p {
    font-size: 14px;
    line-height: 22px;
  }
  .heroMain button {
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 10px;
  }
}
/* Event Section Starts */
.eventSection {
  padding: 60px 0;
  background: #cddee5;
}
.eventMain .head {
  margin-bottom: 70px;
}
.eventMain .head h3 {
  color: #333;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.8px;
}
.eventMain .card {
  background: transparent;
  border: none;
  padding: 5px;
}
.eventMain .card span {
  width: 96px;
  height: 96px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}
.eventMain .card h6 {
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.48px;
  margin-bottom: 18px;
}
.eventMain .card p {
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
  margin: 0;
  max-width: 98%;
}
@media (max-width: 991.98px) and (min-width: 768px) {
  .eventMain .card h6 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .eventMain .card p {
    font-size: 15px;
  }
  .eventMain .card span {
    width: 70px;
    height: 70px;
  }
  .eventMain .card span img {
    width: 50%;
  }
}
@media (max-width: 767.98px) and (min-width: 575.98px) {
  .eventMain .card h6 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .eventMain .card p {
    font-size: 16px;
  }
  .eventMain .card span {
    width: 80px;
    height: 80px;
  }
  .eventMain .card span img {
    width: 50%;
  }
}
@media (max-width: 575.98px) {
  .eventMain .head h3 {
    font-size: 32px;
  }
  .eventSection {
    padding: 40px 0;
  }
  .eventMain .card p {
    max-width: 100%;
  }
}
@media (max-width: 400.98px) {
  .eventMain .card h6 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .eventMain .card p {
    font-size: 15px;
  }
  .eventMain .card span {
    width: 70px;
    height: 70px;
  }
  .eventMain .card span img {
    width: 50%;
  }
  .eventMain .head h3 {
    font-size: 20px;
  }
  .eventMain .head {
    margin-bottom: 40px;
  }
}
/* Contact Section Start */

.contactSection {
  padding: 60px 0;
}
.contactSection .head {
  margin-bottom: 30px;
}
.contactSection .head h4 {
  color: #000;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
}
.contactSection .upload-box {
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 21px;
  border: 1px dashed #afafaf;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.07);
  padding: 4px;
}
.contactSection .upload-box-two {
  height: 313px !important;
}
.contactSection .upload-label {
  color: #757575;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.36px;
}

.contactSection .preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 21px;
}

.contactSection .inputGroup {
  margin-bottom: 20px;
}

.contactSection .inputGroup .inputHead {
  width: 49%;
}

.contactSection .form-control {
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.07) !important;
  border: unset;
  padding: 17px 16px;
  color: #757575;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.contactSection .form-control::placeholder {
  color: #757575;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.contactSection textarea {
  height: 141px;
  resize: none;
}
.contactSection .input-group {
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.07);
  padding: 17px 16px;
  cursor: pointer;
}
.contactSection .input-group .form-control {
  background: transparent;
  box-shadow: unset !important;
  padding: 0;
  position: unset;
}
.contactSection .input-group-text {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-right: 16px;
}
.contactSection .submitBtn {
  padding: 16px 24px;
  width: 100%;
  border-radius: 20px;
  background: #7296a4;
  border: none;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.36px;
}

.contactSection .submitBtn:hover {
  opacity: 0.8;
  transition: all 0.5s;
}

.contactSection input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.contactSection .custom-select {
  border: none;
  outline: none;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.36px;
  margin-right: 4px;
  cursor: pointer;
  background: transparent;
}
.contactSection .btnHead {
  display: none;
}
@media (max-width: 991.98px) and (min-width: 768px) {
  .contactSection .form-control::placeholder {
    font-size: 14px;
  }
  .contactSection .custom-select {
    font-size: 14px;
  }
}
@media (max-width: 767.98px) {
  .contactSection .btnHead {
    display: block;
  }
  .contactSection .formInfo .submitBtn {
    display: none;
  }
  .contactSection .upload-box {
    height: 221px;
  }
  .contactSection .upload-box-two {
    height: 221px !important;
  }
}
@media (max-width: 575.98px) {
  .contactSection .inputGroup .inputHead {
    width: 100%;
    margin-bottom: 14px;
  }
  .contactSection .inputGroup .inputHead:last-child {
    margin-bottom: unset;
  }
  .contactSection .inputGroup {
    margin-bottom: 14px;
  }
  .contactSection .head h4 {
    font-size: 18px;
  }
  .contactSection {
    padding: 40px 0;
  }
}
@media (max-width: 400.98px) {
  .contactSection .head h4 {
    font-size: 16px;
  }
  .contactSection .form-control {
    font-size: 14px;
  }
  .contactSection .form-control::placeholder {
    font-size: 14px;
  }
  .contactSection .submitBtn {
    font-size: 14px;
  }
  .contactSection .custom-select {
    font-size: 14px;
  }
  .contactSection .upload-label {
    font-size: 14px;
  }
}
/* Footer Starts */
footer {
  padding-bottom: 60px;
  text-align: center;
}
footer img {
  max-width: 525px;
  width: 80%;
}

.guestTableMain {
  border-radius: 20px;
  background: #fff;
  padding: 42px;
}
.guestTableMain table {
  width: 100%;
  border-collapse: collapse;
}

.guestTableMain table th {
  padding: 20px 44px;
  background: #9ebecb;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.36px;
}
.guestTableMain table th:first-child {
  border-radius: 20px 0 0 20px;
}
.guestTableMain table th:last-child {
  border-radius: 0 20px 20px 0;
}
.guestTableMain table tbody td {
  padding: 20px 44px;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.48px;
}
.guestTableMain table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.guestTableMain table tbody tr:last-child {
  border-color: transparent;
}
.guestTableMain table tbody td.status {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
}
.guestTableMain table tbody td.status.approved {
  color: #7296a4;
}
.guestTableMain table tbody td.status.decline {
  color: #9ebecb;
}
.guestTableMain table tbody td.status.notSure {
  color: #cddee5;
}
.guestTableMain .pagination ul {
  list-style: none;
  margin-top: 6px;
  padding: 0;
  max-width: 190px;
  width: 90%;
}
.guestTableMain .pagination ul li {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #7296a4;
  color: #7296a4;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.36px;
  cursor: pointer;
}
.guestTableMain .pagination ul li.active {
  background: #7296a4;
  color: #fff;
}
.guestListHead .buttons {
  padding: 38px 0;
}
.guestListHead .buttons button {
  padding: 16px 24px;
  border-radius: 10px;
  background: #7296a4;
  border: none;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.36px;
  margin: 0 10px;
  width: 220px;
}
.guestListHead .buttons button:hover {
  opacity: 0.8;
  transition: all 0.5s;
}
.guestListHead .buttons .deteleBtn {
  background: #9ebecb;
}
.guestTableMain .tableHead::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.guestTableMain .tableHead::-webkit-scrollbar-track {
  background: whitesmoke;
  border-radius: 30px;
}

.guestTableMain .tableHead::-webkit-scrollbar-thumb {
  background: #393939;
  border-radius: 30px;
}
@media (max-width: 767.98px) {
  .guestTableMain .head h4 {
    font-size: 32px;
  }
  .guestTableMain .guestTableMain {
    padding: 30px;
  }
}
@media (max-width: 575.98px) {
  .guestTableMain {
    padding: 20px;
  }
  .guestTableMain .head h4 {
    font-size: 28px;
  }
  .guestTableMain .guestTableMain {
    padding: 20px;
    margin-bottom: 40px;
  }
  .guestTableMain table th {
    padding: 18px 10px;
    font-size: 12px;
  }
  .guestTableMain table tbody td {
    padding: 18px 10px;
    font-size: 12px;
  }
  .guestTableMain table tbody td.status {
    font-size: 12px;
  }
  .guestListHead .buttons {
    display: none !important;
  }
}
@media (max-width: 400.98px) {
  .guestTableMain .tableHead {
    overflow-x: auto;
    width: 100%;
  }
  .guestTableMain table {
    min-width: 200px;
  }
}
/* eventDetailsSection Starts */
.eventDetailsSection {
  padding: 60px 0;
}
.eventCard {
  margin-bottom: 30px;
  border-radius: 20px;
  background: #fff;
  padding: 10px;
}
.eventCard .content {
  flex: 1;
  padding-left: 29px;
}
.eventCard span {
  width: 106px;
  border-radius: 4px;
}
.eventCard span img {
  width: 100%;
}
.eventCard .content p {
  color: #757575;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 14px;
  display: none;
}
.eventCard .content h6 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 14px;
}
.eventCard .content a {
  color: #545454;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-right: 44px;
  text-decoration: none;
}
.eventCard .content a img {
  margin-right: 9px;
}
.guestCards {
  margin-bottom: 60px;
}
.guestCards .card {
  border-radius: 20px;
  background: #7296a4;
  border: none;
  height: 145px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  cursor: pointer;
}
.guestCards .card h6 {
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
}
.guestCards .card h4 {
  color: #fff;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
}
.guestCards .card.approved {
  background: #7296a4;
}
.guestCards .card.decline {
  background: #9ebecb;
}
.guestCards .card.notSure {
  background: #cddee5;
}
.guestListHead .head h4 {
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.56px;
  margin: 0;
}
.guestListHead .head {
  margin-bottom: 36px;
}
.guestListHead .head button {
  padding: 16px 24px;
  border-radius: 10px;
  background: #7296a4;
  border: none;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.36px;
}
.guestListHead .head button:hover {
  opacity: 0.8;
  transition: all 0.5s;
}
.guestListHead .head .input-group {
  width: 40%;
  border-radius: 100px;
  background: #fff;
  padding: 16px;
}
.guestListHead .head .input-group .form-control {
  background: transparent;
  box-shadow: unset !important;
  padding: 0;
  border: none;
  color: #757575;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.guestListHead .head .input-group .form-control::placeholder {
  color: #757575;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.guestListHead .head .input-group .input-group-text {
  border: none;
  background-color: unset;
  padding: 0;
  margin-right: 16px;
}

@media (max-width: 991.98px) {
  .subDetails a {
    width: 50%;
    margin-bottom: 10px;
    margin-right: 0 !important;
  }
}

@media (max-width: 991.98px) and (min-width: 768px) {
  .guestCards .card h4 {
    font-size: 42px;
  }
  .guestCards .card h6 {
    font-size: 18px;
  }
}
@media (max-width: 575.98px) {
  .guestCards .card {
    height: unset;
  }
  .eventCard .content {
    flex: unset;
    width: 100%;
    margin-top: 20px;
  }
  .eventCard .content a {
    width: 100%;
  }
  .guestListHead .head .input-group {
    width: 100%;
  }
  .guestListHead .head h4 {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
  .eventCard .content p {
    display: block;
  }
  .eventCard .content p {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .guestHeadBtn {
    width: 100%;
    justify-content: center;
  }
}
@media (max-width: 400.98px) {
  .eventCard .content h6 {
    font-size: 16px;
  }
  .eventCard .content a {
    font-size: 12px;
  }
  .guestCards .card h4 {
    font-size: 42px;
  }
  .guestCards .card h6 {
    font-size: 18px;
  }
  .guestListHead .head h4 {
    font-size: 24px;
  }
}

/* invitationSection */
.invitationSection {
  padding: 60px 0;
}
.invitationMain .card {
  max-width: 624px;
  margin: 0 auto;
  align-items: center;
  border: none;
  background: url(./assets/imges/invitation-bg.png);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  padding-top: 460px;
  border-radius: 20px;
  overflow: hidden;
}
.invitationMain .cardContent {
  width: 100%;
  padding: 30px 60px;
  text-align: center;
  position: relative;
  z-index: 2;
  background: #e6f0f2;
}
.invitationMain .cardContent::before {
  content: "";
  background: url(./assets/imges/Union.png);
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  top: -200px;
  bottom: 0;
  z-index: -1;
}
.invitationMain .card p {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 18px;
}
.invitationMain .card h2 {
  color: #7296a4;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
}
.invitationMain .card h2 span {
  color: #9ebecb;
}
.invitationMain .card h5 {
  color: #000;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.invitationMain .card h4 {
  color: #000;
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 40px;
}
.invitationMain .card h6 {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.invitationMain .joiningHead {
  max-width: 810px;
  margin: 50px auto 0;
}
.invitationMain .joiningHead h3 {
  color: #000;
  font-family: var(--font3);
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}
.invitationMain .joiningHead button {
  border-radius: 10px;
  padding: 16px 24px;
  margin-right: 19px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.36px;
}
.invitationMain .joiningHead button:nth-child(1) {
  background: #7296a4;
}
.invitationMain .joiningHead button:nth-child(2) {
  background: #9ebecb;
}
.invitationMain .joiningHead button:nth-child(3) {
  background: #cddee5;
}
.invitationMain .joiningHead button:hover {
  opacity: 0.8;
  transition: all 0.5s;
}
@media (max-width: 767.98px) {
  .invitationMain .joiningHead h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .invitationMain .joiningHead .buttons {
    width: 100%;
  }
  .invitationMain .joiningHead button {
    margin: 0 5px !important;
  }
  .invitationMain .card h2 {
    font-size: 55px;
  }
  .invitationMain .card h5 {
    font-size: 30px;
  }
  .invitationMain .card h4 {
    font-size: 37px;
    margin-bottom: 30px;
  }
}
@media (max-width: 575.98px) {
  .invitationMain .card p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .invitationMain .card h2 {
    font-size: 45px;
    margin-bottom: 20px;
  }
  .invitationMain .card h5 {
    font-size: 21px;
  }
  .invitationMain .card h4 {
    font-size: 27px;
  }
  .invitationMain .card h6 {
    font-size: 14px;
  }
  .invitationMain .joiningHead h3 {
    font-size: 30px;
  }
  .invitationMain .cardContent {
    padding: 0 30px 10px;
  }
  .invitationMain .cardContent::before {
    top: -170px;
  }
  .invitationMain .card {
    padding-top: 400px;
  }
  .invitationSection {
    padding: 40px 0;
}
.invitationMain .joiningHead button{
  padding: 16px;
}
}
@media (max-width: 420.98px) {
  .invitationMain .card h2 {
    font-size: 35px;
  }
  .invitationMain .cardContent {
    padding: 0 10px 10px;
  }
  .invitationMain .cardContent::before {
    top: -120px;
  }
  .invitationMain .card {
    padding-top: 300px;
  }
  .invitationMain .card{
    border-radius: 14px;
  }
}
@media (max-width: 345.98px) {
  .invitationMain .card h2 {
    font-size: 25px;
  }
  .invitationMain .card h5 {
    font-size: 18px;
  }
  .invitationMain .card h4 {
    font-size: 22px;
  }
  .invitationMain .joiningHead button{
    padding: 10px;
    border-radius: 6px;
  }
  .invitationMain .joiningHead h3{
    font-size: 25px;
  }
}
