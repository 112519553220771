.popup-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    max-height: 400px;
    }
    .popup-body {
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 10%;
    top: 10%;
    text-align: center;
    margin: auto;
    border-radius: 15px;
    border-color: black;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }